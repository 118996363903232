import { getLocalStorageKey } from '../../utils/local-stoarge.helper';

export const isIOSDevice = (): boolean => {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent) || /iPhone|iPad|iPod/i.test(navigator.platform);

    return isIOS;
};

export const getEMIPercentage = ({
    tdp,
    emiAmount,
    orderValue,
    tenure
}: {
    tdp: number;
    emiAmount: number;
    orderValue: number | string;
    tenure: number;
}) => {
    const overallAmount = tdp + emiAmount * tenure;
    const extraPercentage = ((overallAmount - Number(orderValue)) / Number(orderValue)) * 100;
    const extraPercentageMonthly = extraPercentage / tenure;
    return extraPercentageMonthly.toFixed(2);
};

export const getMerchantProperties = (checkoutId: string, event: string) => {
    try {
        const merchantEventsConfig = getLocalStorageKey(`${checkoutId}_merchant_events_config`);
        if (merchantEventsConfig) {
            const merchantEventsConfigJson = JSON.parse(merchantEventsConfig);
            const properties = merchantEventsConfigJson[event] || {};
            return properties;
        }
        return {};
    } catch {
        return {};
    }
};
